.text-extra2 {
    color: #8151bd;
}

.text-extra1 {
    color: #49d49d;
}

.text-extra3 {
    color: #d3502f;
}

.text-extra4 {
    color: #3f51b5;
}