.selector-body {
    width: 90%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    margin-left: 1em;
    float: right;
}

b {
    color: rgb(42, 42, 42);
}

.selector-body .spacer {
    margin: 50px;
}

.btn-selector {
    font-size: 0.7em !important;
    border-radius: 15px;
}

.transparent-bg {
    background-color: rgba(0,0,0,0);
}

.full-opacity {
    pointer-events: none;
    opacity: 0;
}

.selector-dark {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #b0b0b0;
}

.show-btn {
    border-radius: 15px;
}