.map-overlay {
    background-color: #51cbce;
    border-radius: 5px;
    padding: 4px;
    margin: 0;
    color: #fff;
}

.map-overlay > .city {
    font-size: 10px;
    font-weight: 700;
    margin: 0;
}

.map-overlay > .count {
    font-size: 12px;
    margin: 0;
    font-weight: 900;
}